import React, { useEffect } from 'react'
import SEO from 'components/SEO'
import Layout from 'components/Layout'

const report = '/docs/Cliente-versao1.pdf'

const PrivacyClientsVersion1PDF = () => {
  useEffect(() => {
    if (window) {
      window.location = report
    }
  })
  return (
    <Layout>
      <SEO
        description="Políticas de privacidade de clientes PDF até 16/07/2018"
        title="Políticas de privacidade de clientes PDF até 16/07/2018"
      />
    </Layout>
  )
}

export default PrivacyClientsVersion1PDF
